<template>
  <div>
    <b-modal
      v-model="deleteElement"
      :title="$t('Delete Check')"
      :ok-title="$t('Delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      cancel-variant="primary"
      @close="deleteElement=false"
      @cancel="deleteElement=false"
      @ok="ConfirmDelete"
    >
      <b-badge
        class="mt-1"
        variant="light-danger"
      >
        {{ $t('are you sure you want to delete this Reciept') }}
      </b-badge>
    </b-modal>
    <h3 class="mb-3">
      {{ $t('Reciept') }}
    </h3>

    <b-card
      class="mt-5"
    >
      <b-card-title>
        <b-row>
          <b-col
            cols="1"
          />
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('first_regestirtion') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="2"
          />
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('renew') }} / {{ $t('Sale') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter1"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter1"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="1"
          />
        </b-row>
      </b-card-title>
      <b-row
        v-if="chekker == false"
      >
        <b-col
          cols="4"
          md="4"
        />
        <b-col
          cols="4"
          md="4"
        >
          <h1>
            الرسم   : {{ items[0]['toll'] }}
          </h1>
        </b-col>
        <b-col
          cols="4"
          md="4"
        >
          <b-button
            class="w-100 mb-1"
            variant="gradient-success"
            @click="create"
          >
            {{ $t('Make Payment') }}
          </b-button>
          <b-button
            class="w-100 mb-1"
            variant="gradient-success"
            @click="print()"
          >
            {{ $t('Print') }}
          </b-button>
        </b-col>
        <b-card :title="$t('owwner applicant')">
          <b-row
            v-if="items"
          >
            <b-col
              v-for="el in ['id','owner', 'applicant','date_time', 'phone', 'identify']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="items[0]['owwner_data'][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="items"
          >
            <b-col
              v-for="el in ['no','No','chassis_number', 'chassis_number_type','owner_type1', 'created_year', 'first_registeraion',
                            'cabin_number','cabin_number_type','motor_number_type','motor_reserve_number','motor_reserve_number_type',
                            'motor_number','cabin_reserve_number_type','cabin_reserve_number','expiration_date','toll',
                            'color','fuel_type','car_type','motor_model','motor_manufacturer','wheel1','combustion1',
                            'light1','brake1','block_reason','block1','notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="items[0][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-table
        v-if="items.length>0 && chekker == true"
        :fields="fields"
        responsive="sm"
        :items="items"
        class="table-w-max"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ data.item.no }}
          </b-badge>
        </template>
        <template
          #cell(created_at_updated_at)="data"
        >
          <div>
            <b-badge
              v-if="data.item.created_at"
              v-b-tooltip.hover="{title:data.item.created_at,variant:'success',placement:'top'}"
              variant="light-success"
            >
              <font-awesome-icon
                :icon="['fas', 'table']"
              />
              {{ data.item.created_from }}
            </b-badge>
            <b-badge
              v-if="data.item.updated_from"
              v-b-tooltip.hover="{ title:data.item.updated_from,variant:'warning',placement:'top'}"
              variant="light-warning"
              class="ml-1"
            >
              <font-awesome-icon
                :icon="['fas', 'calendar-week']"
              />
              {{ data.item.updated_from }}
            </b-badge>
          </div>
        </template>
        <template #cell(carr)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(expiration_date)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            <font-awesome-icon
              :icon="['fas', 'table']"
            />
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(owner_type)="data">
          <b-badge
            pill
            :variant="data.item.owner_type?'light-primary':'light-danger'"
          >
            {{ data.item.owner_type? 'خاصة':'عامة' }}
          </b-badge>
        </template>
        <template #cell(carr_data)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(wheel)="data">
          <b-badge
            pill
            :variant="data.item.wheel?'light-primary':'light-danger'"
          >
            {{ data.item.wheel?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(light)="data">
          <b-badge
            pill
            :variant="data.item.light?'light-primary':'light-danger'"
          >
            {{ data.item.light?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(brake)="data">
          <b-badge
            pill
            :variant="data.item.brake?'light-primary':'light-danger'"
          >
            {{ data.item.brake?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(combustion)="data">
          <b-badge
            pill
            :variant="data.item.combustion?'light-primary':'light-danger'"
          >
            {{ data.item.combustion?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(first_check)="data">
          <b-badge
            pill
            :variant="data.item.first_check?'light-primary':'light-danger'"
          >
            {{ data.item.first_check?'مقبول':'مرفوض' }}
          </b-badge>
        </template>

        <template #cell(owwner_data)="data">
          <b-badge
            pill
            :variant="data.item.owwner_data?'light-primary':'light-danger'"
          >
            {{ data.item.owwner_data?data.item.owwner_data.owner:'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(owwner_id)="data">
          <b-badge
            pill
            :variant="data.item.owwner_data?'light-primary':'light-danger'"
          >
            {{ data.item.owwner_data?data.item.owwner_data.id:'ليست مرتبطة' }}
          </b-badge>
        </template>

        <template #cell(fuel_type)="data">
          {{ $t(data.item.fuel_type) }}
        </template>
        <template #cell(car_type)="data">
          {{ $t(data.item.car_type) }}
        </template>
        <template #cell(platee)="data">
          <b-badge
            pill
            :variant="data.item.platee_data?'light-primary':'light-danger'"
          >
            {{ data.item.platee_data?data.item.platee_data.number:'ليست مرتبطة' }}
          </b-badge>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center"
      >
        <b-col
          v-if="chekker == true"
          cols="4"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No Data Available') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            per-page="10"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      id="printMe"
      style="visibility: hidden;"
    >

      <div style="width: 454px!important; height: 700px; background-color: white; margin-left: 150px!important;">
        <div
          v-if="this.items"
          style="
          width: 170px!important;
            height: 120px;
            position: fixed;
            margin-top: 0px;
            margin-left: 10px;
            "
        >
          <img
            src="@/assets/images/logo/logo.jpg"
            style="width: 80px;
            height: 86px;"
          >
          <label style=" color: black; text-align: left;font-size: 11px!important; padding-left: 15px;">{{ currentDateTime() }} : التاريخ </label>
        </div>

        <label
          style="
              margin-bottom:0px!important;margin-left: 150px;margin-right: 0px!important; text-align: center;
               color: red; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';
                 font-size: 20px;"
        >وصل قبض
          <br> <label
            style="border: 2px black solid;
                  color: black; font-weight: bolder;
                  padding:0px 40px;
                  margin-top: 20px;
                  margin-right: 20px;
                   font-family: 'Calibri'; font-size: 14px;"
          >رقم : {{ this.items[0].RecieptNo }}
          </label>
        </label>
        <label
          style="text-align: end;
              float: right; font-size: 16px!important;
              position: fixed;
              top: 25px;
              font-weight: bold;margin-right: 0px!important;
font-family: 'Adobe Arabic"
        > حكومة الإنقاذ السورية<br>
          المؤسسة العامة للنقل<br>
          مديرية النقل في {{ user.company_data.name }} <br>
        </label>
        <div style="position:absolute; margin-top: 20px;">
          <table>
            <tr style="border: 2px solid black;">
              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 173px;"
              >{{ this.items[0].chassis_number }}</td>

              <td
                style="text-align: center; color: #0d6aad; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
              >: رقم الشاصيه </td>
              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
              >{{ this.items[0].platee }}</td>
              <td
                style="text-align: center; color: #0d6aad; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 90px;"
              >: رقم اللوحة </td>
            </tr>
            <tr>
              <td
                v-if="this.items[0].owwner_data"
                colspan="4"
                style="text-align: right; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
              >  المالك : {{ this.items[0].owwner_data.owner }}       </td>
            </tr>
            <tr style="border: 2px solid black;">
              <td
                colspan="2"
                style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط لا غير</td>

              <td
                style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >المبلغ</td>

              <td
                style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >البيان</td>
            </tr>
            <tr
              v-if="this.items[0].first_fee"
              style="border: 2px solid black;"
            >
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].first_fee) }} دولار</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].first_fee }} <span>$</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >رسم التسجيل</td>
            </tr>
            <tr
              v-if="this.items[0].yearly_fee"
              style="border: 2px solid black;"
            >
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].yearly_fee) }} دولار</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].yearly_fee }} <span>$</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >الرسم السنوي</td>
            </tr>
            <tr
              v-if="this.items[0].renew_fee"
              style="border: 2px solid black;"
            >
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].renew_fee) }} دولار</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].renew_fee }} <span>$</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >رسم التجديد</td>
            </tr>
            <tr
              v-if="this.items[0].pay_fee"
              style="border: 2px solid black;"
            >
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].pay_fee) }} دولار</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].pay_fee }} <span>$</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >رسم البيع</td>
            </tr>
            <tr
              v-if="this.items[0].other_fee"
              style="border: 2px solid black;"
            >
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].other_fee) }} دولار</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].other_fee }} <span>$</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >رسوم أخرى</td>
            </tr>
            <tr style="border: 2px solid black;">
              <td
                colspan="2"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >فقط {{ $t(this.items[0].toll) }} ليرة تركية</td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >{{ this.items[0].toll }} <span>&#8378;</span></td>

              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
              >الإجمالي</td>
            </tr>

            <tr>
              <td
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
              >أمين الصندوق</td>
              <td
                colspan="3"
                style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px"
              />

            </tr>
          </table>

          <div style="alignment: center; height: 40px" />
          <div style="alignment: center; height: 70px">
            <label style="margin-right: 60%; font-weight: bold; text-align: left!important; font-size: 8px; font-family: 'Traditional Arabic'">{{ }}  {{ }}</label>
            <label style=" font-weight: lighter; color: gray;  font-size: 18px; font-family: 'Traditional Arabic'">(النسخة الأصلية)</label>
            <hr style="margin-top: 50px!important;margin-bottom: 50px!important;">
            <div style="width: 454px!important; height: 650px; background-color: white;">
              <div
                style="
          width: 170px!important;
            height: 120px;
            position: fixed;
            margin-top: 0px;
            margin-left: 10px;
            "
              >
                <img
                  src="@/assets/images/logo/logo.jpg"
                  style="width: 80px;
            height: 86px;"
                >
                <label style=" color: black; text-align: left; font-size: 11px!important; padding-left: 15px;">{{ currentDateTime() }} : التاريخ </label>
              </div>

              <label
                style="
              margin-bottom:0px!important;margin-left: 150px;margin-right: 0px!important; text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';
                 font-size: 20px;"
              >وصل قبض
                <br> <label
                  style="border: 2px black solid;
                  color: black; font-weight: bolder;
                   padding:0px 40px;
                  margin-top: 20px;
                  margin-right: 20px;
                   font-family: 'Calibri'; font-size: 14px;"
                >رقم : {{ this.items[0].RecieptNo }}
                </label>
              </label>
              <label
                style="text-align: end;
              float: right; font-size: 16px!important;
              font-weight: bold;margin-right: 0px!important;
              position: absolute;
font-family: 'Adobe Arabic"
              > حكومة الإنقاذ السورية<br>
                المؤسسة العامة للنقل<br>
                مديرية النقل في {{ user.company_data.name }} <br>
              </label>
              <div style="position:absolute; margin-top: 20px;">
                <table>
                  <tr style="border: 2px solid black;">
                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 173px;"
                    >{{ this.items[0].chassis_number }}</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                    >: رقم الشاصيه </td>
                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                    >{{ this.items[0].platee }}</td>
                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 90px;"
                    >: رقم اللوحة</td>
                  </tr>
                  <tr>
                    <td
                      v-if="this.items[0].owwner_data"
                      colspan="4"
                      style="text-align: right; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                    >  المالك : {{ this.items[0].owwner_data.owner }}       </td>
                  </tr>
                  <tr style="border: 2px solid black;">
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط لا غير</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >المبلغ</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >البيان</td>
                  </tr>
                  <tr
                    v-if="this.items[0].first_fee"
                    style="border: 2px solid black;"
                  >
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].first_fee) }} دولار</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].first_fee }} <span>$</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >رسم التسجيل</td>
                  </tr>
                  <tr
                    v-if="this.items[0].yearly_fee"
                    style="border: 2px solid black;"
                  >
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].yearly_fee) }} دولار</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].yearly_fee }} <span>$</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >الرسم السنوي</td>
                  </tr>
                  <tr
                    v-if="this.items[0].renew_fee"
                    style="border: 2px solid black;"
                  >
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].renew_fee) }} دولار</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].renew_fee }} <span>$</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >رسم التجديد</td>
                  </tr>
                  <tr
                    v-if="this.items[0].pay_fee"
                    style="border: 2px solid black;"
                  >
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].pay_fee) }} دولار</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].pay_fee }} <span>$</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >رسم البيع</td>
                  </tr>
                  <tr
                    v-if="this.items[0].other_fee"
                    style="border: 2px solid black;"
                  >
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].other_fee) }} دولار</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].other_fee }} <span>$</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >رسوم أخرى</td>
                  </tr>
                  <tr style="border: 2px solid black;">
                    <td
                      colspan="2"
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >فقط {{ $t(this.items[0].toll) }} ليرة تركية</td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >{{ this.items[0].toll }} <span>&#8378;</span></td>

                    <td
                      style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                    >الإجمالي</td>
                  </tr>
                </table>
                <div style="alignment: center; height: 70px">
                  <label style="margin-right: 60%; font-weight: bold; text-align: left!important; font-size: 8px; font-family: 'Traditional Arabic'">{{ }}  {{ }}</label>
                  <label style=" font-weight: lighter; color: gray;  font-size: 18px; font-family: 'Traditional Arabic'">(نسخة القسم)</label>

                </div>

              </div>
            </div>

          </div>

        </div>
      </div></b-card>
  </div></template>

<script>

export default {
  data() {
    return {
      deleteElement: false,
      filter: null,
      filter1: null,
      filter2: null,
      element: null,
      currentPage: 1,
      totalRows: 0,
    }
  },
  computed: {

    Tolls() { return this.$store.getters['toll/GetElements'] },
    user() {
      return this.$store.getters['auth/GetAuth']
    },
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'owwner_id', label: this.$t('owwner_id') },
        { key: 'No', label: this.$t('CkeckkNo') },
        { key: 'chassis_number', label: this.$t('chassis_number') },
        { key: 'chassis_number_type', label: this.$t('chassis_number_type') },
        { key: 'owner_type', label: this.$t('owner_type') },
        { key: 'created_year', label: this.$t('created_year') },
        { key: 'first_registeraion', label: this.$t('first_registeraion') },
        { key: 'cabin_number', label: this.$t('cabin_number') },
        { key: 'cabin_number_type', label: this.$t('cabin_number_type') },
        { key: 'cabin_reserve_number', label: this.$t('cabin_reserve_number') },
        { key: 'cabin_reserve_number_type', label: this.$t('cabin_reserve_number_type') },
        { key: 'motor_number', label: this.$t('motor_number') },
        { key: 'motor_number_type', label: this.$t('motor_number_type') },
        { key: 'motor_reserve_number', label: this.$t('motor_reserve_number') },
        { key: 'motor_reserve_number_type', label: this.$t('motor_reserve_number_type') },
        { key: 'motor_manufacturer', label: this.$t('motor_manufacturer') },
        { key: 'motor_model', label: this.$t('motor_model') },
        { key: 'car_type', label: this.$t('car_type') },
        { key: 'fuel_type', label: this.$t('fuel_type') },
        { key: 'color', label: this.$t('color') },
        { key: 'fee', label: this.$t('Fee') },
        { key: 'expiration_date', label: this.$t('expiration_date') },
        { key: 'brake', label: this.$t('brake') },
        { key: 'light', label: this.$t('light') },
        { key: 'combustion', label: this.$t('combustion') },
        { key: 'wheel', label: this.$t('wheel') },
        { key: 'owwner_data', label: this.$t('owwner') },
        { key: 'carr_data', label: this.$t('car') },
        { key: 'platee', label: this.$t('plate') },
        { key: 'notes', label: this.$t('notes') },
        { key: 'created_at_updated_at', label: this.$t('created_at_updated_at') },
      ]
    },
    items() {
      return this.$store.getters['reciept/GetElements']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter}`
    },
  },
  watch: {
    currentPage() {
      this.getElement()
    },
  },
  created() {
    this.$store.dispatch('toll/GetPluckedElements')
    this.chekker = true
    this.getElement()
  },
  methods: {
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      const dateTime = `${date}`

      return dateTime
    },
    onEnter() {
      this.chekker = false
      this.currentPage = 1
      this.getElement()
    },
    onEnter1() {
      this.chekker = false
      this.currentPage = 1
      this.getElement1()
    },
    getElement() {
      this.$store.dispatch('reciept/GetElements', `page=${this.currentPage}&search=${this.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    getElement1() {
      this.$store.dispatch('reciept/GetElementsByRenew', `page=${this.currentPage}&search=${this.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    Delete(val) {
      this.deleteElement = true
      this.element = val
    },
    ConfirmDelete() {
      this.$store.dispatch('reciept/Delete', this.element)
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe')
      this.filter = null
      this.chekker = true
      this.getElement()
      this.clearData()
    },
    create() {
      this.errors = []
      this.$store.dispatch('reciept/Create', this.items[0]).then(() => {
        setTimeout(() => { this.print(this.message) }, 1500)
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
